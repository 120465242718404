// IMPORTS
@import (reference) "../settings.less";

.older-browser .field-wrapper:not(.checking-reg) label {
    transform: translateY(-25px);
    cursor: default;
    background-color: #fff;
}

.field-group {
    clear: both;
    display: flex;
    gap: 0 15px;
    
    > * {
        flex: 1;
    }

    &.--65-35 {
        > div:first-child {
            width: 65%;
        }
    }
}

@media(max-width: 1100px) {
    .field-group {
        flex-wrap: wrap;

        > * {
            flex-basis: 100%;
        }
    }
}

.field-wrapper:not(.checking-reg) {
    clear: both;
    position: relative;
    margin-bottom: 15px;

    label {
        position: absolute;
        cursor: text;
        top: 15px;
        left: 18px;
        padding: 0;
        line-height: 20px;
        max-width: ~"calc(100% - 38px)";
        overflow: hidden;
        border-radius: var(--borderRadius);
        text-overflow: ellipsis;
        user-select: none;
        pointer-events: none;
        font-size: var(--fontSize);
        transform: none;
        transition-duration: 0.1s;
        z-index: 4;
        color: @header-color;
    }

    input[data-masked-field] + label {
        transition: none;
    }

    input {
        padding-block: 21px 6px;
        color: @header-color;
        font-size: 14px;

        @media (max-width: 1279px) {
            font-size: 16px;
        }
    }

    select {
        padding: 21px 40px 6px 18px;
        appearance: none;

        @media (max-width: 1279px) {
            font-size: 14px;
        }
    }

    &:has(select):after {
            content: '\e800';
            font-family: icomoon;
            position: absolute;
            font-size: 11px;
            top: 50%;
            transform: translateY(-50%);
            right: 15px;
    } 

    &:has(select:focus):after {
        transform: translateY(-50%) rotate(-180deg);
    } 

    textarea {
        padding-block: 21px 6px;
        color: @header-color;
    }

    &:has(textarea) label {
       backdrop-filter: blur(6px); 
    }

    &.filled label,
    &:has(select) label,
    input:focus + label,
    textarea:focus + label,
    input:-webkit-autofill + label {
        transform: translateY(-10px);
        cursor: default;
        font-size: 12px;
        line-height: 1.5;
        transition: all 0.15s ease-in-out;
        transition-property: font-size, color;
        color: @font-color;
    }

    &.filled {

        input[type=text],
        input[type=password],
        input[type=email],
        textarea {
            padding-right: 40px;
        }
    }

    &.valid {

        input,
        textarea,
        select {
            border-color: @success-color;
            background-color: lighten(@success-color, 55%);
        }

        &:after {
            content: '\e801';
            font-family: icomoon;
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            color: @success-color;
            line-height: 50px;
            text-align: center;
            font-size: 30px;
            z-index: 2;
        }

        select {
            z-index: 3;
        }

        label {
            cursor: default;
        }
    }

    &.invalid:not(.filled) {

        select {
            border-color: @error-color;
            background-color: lighten(@error-color, 52%);

            & + label {
                transform: translateY(-25px);
                cursor: default;
                color: @error-color;
            }
        }
    }

    &.invalid.filled {

        &:after {
            content: '!';
            font-family: sans-serif;
            position: absolute;
            top: 0;
            right: 0;
            width: 50px;
            color: @error-color;
            line-height: 50px;
            text-align: center;
            font-size: 30px;
            z-index: 2;
        }

        input,
        textarea,
        select {
            border-color: @error-color;
            background-color: lighten(@error-color, 52%);


            & + label {
                cursor: default;
            }
        }

        .form-hint {
            color: @error-color;
        }
    }

    [data-validator*="required"] + label:after {
        content: '*';
        color: @error-color;
        margin-left: 3px;
        font-size: 12px;
        position: relative;
        top: -1px;
    }
}

form {

    .row-wrapper {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        &.address-wrapper {
            .grid();
            grid-template-columns: ~"calc(50% - (15px / 2))" 1fr;
            gap: 15px;
        }
    }
}

/* INPUT-ICONS */
.field-wrapper:not(.checking-reg) {

    &.icon {

        label {
            left: 45px;
            max-width: ~"calc(100% - 50px)";
        }

        input {
            padding-left: 45px;
        }

        &:before {
            font-family: icomoon;
            position: absolute;
            top: 0;
            left: 0;
            width: 50px;
            line-height: 50px;
            text-align: center;
            color: @font-color;
            font-size: 20px;
            z-index: 3;
            pointer-events: none;
        }

        &.icon-email:before {
            content: '\e805';
            font-size: 15px;
        }

        &.icon-password:before {
            content: '\e812';
            font-size: 18px;
        }

        &.icon-phone:before {
            content: '\e80a';
        }

        &.icon-user:before {
            content: '\e811';
        }
    }
}

.check-person {
    margin: 30px 0 15px;
    color: @header-color;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 30px;

    .check-simple {
        position: relative;
    }

    input[type="radio"] {
        position: absolute;
        opacity: 0;

        &:checked {

            & + label:before {
                content: '\e904';
            }
        }

        & + label {
            position: relative;
            cursor: pointer;
            padding-left: 30px;

            &:before {
                content: '\e905';
                font-family: icomoon;
                font-size: 20px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 0;
                line-height: 1;
                margin-top: -1px;
                color: @main-color;
            }
        }
    }
}

.toShowCompany {
    padding-bottom: 20px;
    padding-top: 10px;

    .field-wrapper:last-child {
        margin-bottom: 0;
    }
}

.checkbox-wrapper {
    border: 1px solid @border-color;
    border-radius: var(--borderRadius);
    padding: 19px 29px;
    background-color: @light-bg;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .field-wrapper {
        position: relative;

        label {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            user-select: none;
            color: @header-color;
            display: block;
            min-height: ~"calc(var(--fontSize) * var(--lineHeight))";

            a {
                text-decoration: underline;

                &:hover {
                    text-decoration: none;
                }
            }

            &:before {
                content: '\f096';
                position: absolute;
                top: -1px;
                left: 0;
                font-family: icomoon;
                color: @header-color;
                font-size: 20px;
                line-height: ~"calc(var(--fontSize) * var(--lineHeight))";
            }
        }

        input {
            position: absolute;
            opacity: 0;

            &[data-validator*=required] + label:after {
                content: '*';
                color: @error-color;
                margin-left: 5px;
            }

            &:checked + label:before {
                content: '\e816';
            }
        }

        .form-hint {
            color: @error-color;
            clear: both;
            margin-top: 5px;
        }
    }
}

.checking-reg.invalid label {
    color: @error-color;

    &:before {
        color: @error-color;
    }
}

.cart-single-checkbox {

    .checking-reg {

        input:checked + label:after {
            content: '\e816';
        }

        label {
            position: relative;
            padding-left: 30px;
            cursor: pointer;
            color: @header-color;
            display: block;
            width: fit-content;
            min-height: ~"calc(var(--fontSize) * var(--lineHeight))";

            &:after {
                content: '\f096';
                position: absolute;
                top: -1px;
                left: 0;
                font-family: icomoon;
                color: @header-color;
                font-size: 20px;
                line-height: ~"calc(var(--fontSize) * var(--lineHeight))";
            }
        }

        input {
            opacity: 0;
            position: absolute;
        }
    }

    .row-wrapper {
        margin-top: 0;
    }

    .toShowPassword,
    .to-show {
        display: none;
    }
}

.checkbox-inline {
    padding: 15px 0 30px;
    position: relative;

    input:checked + label:after {
        content: '\e816';
    }

    label {
        position: relative;
        padding-left: 30px;
        cursor: pointer;
        color: @header-color;
        display: block;
        width: fit-content;
        min-height: ~"calc(var(--fontSize) * var(--lineHeight))";

        &:after {
            content: '\f096';
            position: absolute;
            top: -1px;
            left: 0;
            font-family: icomoon;
            color: @main-color;
            font-size: 20px;
            line-height: ~"calc(var(--fontSize) * var(--lineHeight))";
        }
    }

    input {
        position: absolute;
        opacity: 0;
    }
}

/* LOGIN-FORM */
.login-form {

    &.form-wrapper {
        width: 100%;
        margin: 0;
        display: flex;
        flex-wrap: wrap;
    }

    > .signup-wrapper {
        width: 50%;
        padding-right: 40px;
        border-right: solid 1px @border-color;

        &:nth-child(2) {
            border-right: none;
            padding-left: 40px;
            padding-right: 0;

            ul {
                margin-bottom: 24px;
            }
        }
    }

    &.logged {

        .logged-header {
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-bottom: 20px;

            h2 {
                padding-left: 70px;
                color: @header-color;
                font-size: 26px;
                position: relative;

                &:before {
                    content: '\e811';
                    font-family: icomoon;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 50px;
                    height: 56px;
                    line-height: 56px;
                    font-weight: normal;
                    text-align: center;
                    font-size: 48px;
                    color: @main-color;
                }

                small {
                    display: block;
                    font-family: @main-font-family;
                    color: @font-color;
                    font-weight: normal;
                    font-size: var(--fontSize);
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                gap: 2px;

                a {
                    display: inline-flex;
                    align-items: center;
                    line-height: 28px;
                    text-transform: uppercase;
                    color: @font-color;

                    &:hover {
                        color: @header-color;
                    }

                    &:before {
                        content: '';
                        font-family: icomoon;
                        margin-right: 8px;
                        position: relative;
                        line-height: 1px;
                    }
                }

                .user-logout a:before {
                    content: '\e803';
                    font-size: 12px;
                }

                .user-shop-contact a:before {
                    content: '\e814';
                    font-size: 20px;
                }
            }

            .logged-discount-info {
                font-size: 16px;
                width: 100%;

                b {
                    color: @header-color;
                }
            }

            form {
                width: 100%;

                fieldset {
                    padding: 0;
                    margin: 0;
                    border: 0;
                }

                label {
                    text-align: left;

                    &:after {
                        font-size: 22px;
                    }
                }
            }
        }

        .logged-menu {
            .grid();
            grid-template-columns: repeat(2, minmax(0,1fr));
            gap: 10px;

            li {
                border: 1px solid @border-color;
                position: relative;
                overflow: hidden;

                &:hover {

                    &:before {
                        transform: translateY(-50%);
                        transition-delay: 0.2s;
                    }
                }

                &:before {
                    .small-icon();
                    font-size: 92px;
                    position: absolute;
                    top: 50%;
                    left: 0;
                    transform: translate(-24%, -24%);
                    transform-origin: left bottom;
                    color: @main-color;
                }

                @media(min-width: 1280px) {
                    &:before {
                        transition: 0.2s transform ease-in-out;
                    }
                }
            }

            a {
                display: block;
                padding: 30px;
                color: @header-color;
                font-size: 21px;
                text-transform: uppercase;
                position: relative;
                text-align: right;
                z-index: 2;
                white-space: nowrap;

                small {
                    color: @font-color;
                    font-size: 14px;
                    display: block;
                }
            }

            .user-addresses-link:before {
                content: "\e808";
                font-size: 80px;
                left: 5px;
            }

            .user-orders-link:before {
                content: "\e911";
            }

            .user-carts-link:before {
                content: "\e90f";
            }

            .user-files-link:before {
                content: "\e910";
            }
        }
    }
}

#data-person-form .address-select-list {
    padding: 20px 0;
}

.address-select-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    li:not(.no-addresses) {
        border: 1px solid @border-color;
        position: relative;
        padding: 15px;
        padding-bottom: 55px;
        min-width: ~"calc((100% - 60px) / 4)";
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        max-width: 50%;
        background-color: white;

        &.selected {
            border-color: @main-color;

            strong {
                color: @main-color;
            }
        }

        strong {
            position: absolute;
            top: -1px;
            left: 10px;
            background-color: white;
            transform: translateY(-50%);
            padding: 3px 5px;
            color: @header-color;
            max-width: ~"calc(100% - 20px)";
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    p {

        span {
            display: block;
        }
    }

    .buttons-wrapper {
        display: flex;
        gap: 5px;
        position: absolute;
        bottom: 15px;
        left: 15px;
        width: ~"calc(100% - 30px)";

        a {
            color: @main-color;
            border: 1px solid @main-color;
            flex-grow: 1;
            text-align: center;
            padding: 5px;
            user-select: none;

            &:hover {
                border-color: @header-color;
                color: @header-color;
            }
        }
    }
}

#address-edit {
    margin-top: 40px;
}

.oauth-signup {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px 30px;

    > span {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 40px 0 0;

        &:before, &:after {
            content: '';
            flex-grow: 1;
            height: 1px;
            background-color: @border-color;
        }

        &:before {
            margin-right: 40px;
        }

        &:after {
            margin-left: 40px;
        }
    }

    > a.confirm-button {
        min-width: 170px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        color: #2c3740;
        border-radius: 0;
        border-width: 2px;

        &.facebook-login-button {
            background-color: #4267b2;
            color: white;
            border-color: #4267b2;
        }

        > svg {
            margin-right: 10px;
            position: relative;
            top: -1px;
        }

        &.google-login-button {
            border: 2px solid #2c3740 !important;
        }

        &.apple-login-button {
            border: 2px solid @header-color;
            background-color: @header-color;
            color: white;

            svg {
                transform: scale(1.2);
            }
        }
    }
}

a.reminder-link {
    color: @font-color;
    text-decoration: underline;
    margin: 10px 0;
    display: inline-block;

    &:hover {
        color: @header-color;
    }
}

.error {
    clear: both;
    display: block;
    color: @error-color;

    &:empty {
        margin: 0;
    }
}

/* ADVANCED-SEARCH */
#advanced-search {

    label {
        font-size: 15px;
        color: @header-color;
        display: block;
        margin-bottom: 5px;
    }

    input.proper,
    select {
        margin-bottom: 25px;
    }

    #slider-handles {
        margin: 20px 16px;
    }

    #price-amounts {
        margin-top: 32px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 10px;

        input[type=text] {
            width: 120px;
            height: 48px;
            text-align: center;
        }
    }

    .confirm-button {
        height: 48px;
        margin-top: 32px;
    }

    @media(max-width: 1279px) {
        #price-amounts {
            justify-content: flex-start;
            margin-top: 12px;
        }

        .confirm-button {
            margin-top: 24px;
            width: 100%;
        }
    }

    @media (max-width: 350px) {
        #price-amounts {
            gap: 24px 4px;

            input {
                width: auto;
            }
        }
    }
}

/* ASKBOX-CMS */
.add-comment-form {
    padding-right: 44%;
    position: relative;

    &:after {
        content: '\e80c';
        font-family: icomoon;
        position: absolute;
        top: 50%;
        right: 14%;
        transform: translateY(-50%);
        font-size: 95px;
        color: #C5C5C5;
    }
}

#overallValidation {
    padding: 10px;
    border: 2px solid @error-color;
    color: @error-color;
    text-align: center;
    display: block;
    margin: 10px 0;
    font-weight: 600;

    ul {
        list-style: none !important;
        padding: 0 !important;
        margin: 0 !important;
    }
}

.fields-required-text {
    width: 100%;
    color: @font-color;
    font-size: 12px;
    padding-top: 10px;

    span {
        color: @error-color;
    }
}

.contact-form-text {
    margin-top: 15px;

    &:empty {
        display: none;
    }
}

// USER FILES
.user-files {
    display: flex;
    flex-direction: column;

    > .headline {
        margin-bottom: 32px;
    }

    .return-button {
        margin-top: 64px;
    }
}

.download-sections-wrapper {
    columns: 3;
    column-gap: 46px;
    column-rule: 1px dashed @border-color;
}

.download-section {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 42px;
    break-inside: avoid;

    &:last-child {
        margin-bottom: 0;
    }

    > .headline {
        font-size: 20px;
        margin-bottom: 0 !important;
        position: relative;
        padding-left: 40px;

        &:before {
            .small-icon();
            content: "\e910";
            color: @main-color;
            font-size: 28px;
            position: absolute;
            top: -2px;
            left: 0;
        }
    }

    ul {
        display: flex;
        flex-direction: column;
        gap: 11px;
        padding-left: 23px;
    }

    li {
        position: relative;
        padding-left: 18px;

        &:before {
            content: '-';
            position: absolute;
            left: 0;
            top: 2px;
            color: @main-color;
        }

        a {
            font-size: 16px;
            color: @header-color;
        }

        p {
            font-size: 14px;
            color: @font-color;
        }
    }
}